import React, { memo, useContext } from 'react';
import { TitleStyled, PStyled } from '../../styles/pages/expired';
import Button from '../../components/button/button';
import pages from '../../constants/pages.json';
import { returnWhatsapp } from '../../utils/browser';
import Layout from '../../components/responsive/layout';
import Topbar from '../../components/responsive/topbar';
import { CompanyContext } from '../../context/company.context';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../context/lang.context';

declare const window: any;

const ExpiredPage = ({ location }: PageProps) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          RETURN_WHATSAPP
          EXPIRED {
            TITLE_1
            TITLE_2
            ADD_USER {
              MESSAGE_1
              MESSAGE_2
              MESSAGE_3
              MESSAGE_4
            }
          }
        }
      }
    }
  `);
  const { state } = location;
  const { getPublicURL }= useContext(CompanyContext);
  const MiddleMessage = memo(() => {
    const windowGlobal = typeof window !== 'undefined' && window;
    const { messageMiddleFrom } = state ?? windowGlobal?.history?.state ?? {};

    switch (messageMiddleFrom) {
      case pages.USER.UPDATE_USER_DETAIL:
        return (
          <PStyled>
            {t(data).EXPIRED.ADD_USER.MESSAGE_1}{' '}
            <b>{t(data).EXPIRED.ADD_USER.MESSAGE_2}</b>{' '}
            {t(data).EXPIRED.ADD_USER.MESSAGE_3}{' '}
            <b>{t(data).EXPIRED.ADD_USER.MESSAGE_4}</b>
          </PStyled>
        );
      default:
        return null;
    }
  });

  return (
    <Layout>
      <Topbar />
      <div className="content">
        <article className="flex flex-col items-center">
          <TitleStyled>
            <b>{t(data).EXPIRED.TITLE_1}</b> {t(data).EXPIRED.TITLE_2}
          </TitleStyled>
          <img className="w-5/6" src={getPublicURL(`/theme/assets/images/error/expired.svg`)} />
          <MiddleMessage />
          <Button color={'primary'} type="button" onClick={() => returnWhatsapp()} className="mt-12">
            {t(data).RETURN_WHATSAPP}
          </Button>
        </article>
      </div>
    </Layout>
  );
};

export default ExpiredPage;
