import styled from '@emotion/styled';
import tw from 'twin.macro';
import { GatsbyImage } from 'gatsby-plugin-image';

const TitleStyled = styled.h1`
  ${tw`text-center text-4xl text-red my-8 font-thin`}

  b {
    ${tw`font-bold`}
  }
`;

const ImgStyled = styled(GatsbyImage)`
  ${tw`w-5/6`}
`;

const PStyled = styled.p`
  ${tw`text-gray-600 text-sm pt-6 px-5 -mb-8 text-center tracking-wide`}
`;

export { TitleStyled, ImgStyled, PStyled };
